import useSWR from "swr";
import styled from "styled-components";
import type { AxiosError } from "axios";
import Axios from "axios";
import {
  MagicDocIcon,
  SearchIcon,
  XIcon,
} from "../../../../components/Icons/Icons";
import { useTranslation } from "react-i18next";
import { endpoints } from "../../../../endpoints";
import { useDebounce } from "../../../../util/hooks";
import type {
  DataMutate,
  OptionType,
  SupportedLanguage,
} from "../../../../types/types";
import {
  isAxiosError,
  useStoreState,
  useUpdateProductStatus,
} from "../../../../util/util";
import { Table } from "../../../../components/Table/Table";
import React, { useState, useContext, useEffect } from "react";
import { H6 } from "../../../../components/Typography/Typography";
import { DropDown } from "../../../../components/DropDown/DropDown";
import type {
  Assets,
  PIMAssetsResponse,
  PIMProduct,
  SupportedAssetType,
} from "../../../../types/types.PIM";
import { PrimaryButtonFitContainer } from "../../../../components/Buttons/Buttons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import type { AssetCategory } from "../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import {
  GetDocumentIcon,
  TrimmedName,
  get_content_type,
} from "../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import type { Row } from "react-table";
import ReactTooltip from "react-tooltip";
import { SingleSelectNoController } from "../../../../components/SingleSelectNoController/SingleSelectNoController";
import { GridAssetView } from "../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/GridAssetView/GridAssetView";

const TableContainer = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.colors.secondaryLightGray};
`;

const ScrollArea = styled.div`
  max-height: 460px;
  overflow-y: auto;
  display: block;
`;

const SearchWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 52px;
  margin-bottom: 28px;
  > svg {
    position: absolute;
    right: 13px;
    top: 14px;
    path {
      fill: #9fa4aa;
    }
  }
`;

const FilterDropdownContainer = styled.div`
  div[class*="DropDown__DropDownModalWrapper"] {
    left: auto !important;
    right: 0;
  }
  .view_type,
  .category_type {
    z-index: 2;
  }
`;

const InnerTitleText = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  min-height: 52px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  font-family: Inter;
  padding: 10px 13px 10px 13px;
  font-size: 13px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.tertiaryBorder};
    box-shadow: 0 0 1px 1px ${({ theme }) => theme.tertiaryBorder};
    outline: none;
  }
`;

const ClearInput = styled.span`
  position: absolute;
  top: 14px;
  right: 13px;
  cursor: pointer;
`;

type QueryType = {
  q?: string;
};

export const ExistingAssets = <ParentData,>({
  title,
  onComplete,
  onAddAssets,
  product,
  products,
  languages,
  fetchParentData,
}: {
  title: "Documents" | "Digital Media";
  product: PIMProduct;
  onComplete?: (productId?: string) => void;
  fetchParentData: DataMutate<ParentData>;
  onAddAssets?: ({
    selected_assets,
    selected_product,
    selected_language,
  }: {
    selected_assets: Assets[];
    selected_product?: OptionType<string>;
    selected_language?: OptionType<SupportedLanguage>;
  }) => Promise<void>;
  products?: OptionType<string>[];
  languages?: OptionType<SupportedLanguage>[];
}) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const [categoryType, setCategoryType] = useState<string>("All");
  const [viewType, setViewType] = useState<"list" | "grid">("list");
  const [categoryTypes, setCategoryTypes] = useState<string[]>([]);

  const { tenant_id } = useStoreState();

  const [query, setQuery] = useState<QueryType>();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [selectedItems, setSelectedItems] = useState<Array<Row<Assets>>>([]);
  const [selectedRows, setSelectedRows] =
    useState<Record<string, Array<Row<Assets>>>>();
  const [selected_product, set_selected_product] =
    useState<OptionType<string>>();
  const [selected_language, set_selected_language] =
    useState<OptionType<SupportedLanguage>>();

  const { notifySuccess, notifyError } = useContext(Notifications);

  const updateProductStatus = useUpdateProductStatus({
    product,
  });

  function constructQuery({
    baseURL,
    queryParams,
    asset_category,
  }: {
    baseURL: string;
    queryParams?: QueryType;
    asset_category: string | string[];
  }) {
    const params = new URLSearchParams();
    if (queryParams?.q) {
      params.append("q", queryParams?.q);
    }
    if (asset_category !== "All") {
      if (Array.isArray(asset_category)) {
        asset_category.forEach((category) =>
          params.append("asset_category", category)
        );
      } else {
        params.append("asset_category", asset_category);
      }
    }
    params.append("limit", "100");
    params.append("show_generated_assets", "false");
    return baseURL + "?" + params;
  }

  const { data: assetsResponse, error: assetsError } =
    useSWR<PIMAssetsResponse>(
      constructQuery({
        baseURL: endpoints.v2_storefronts_id_pim_assets(tenant_id),
        queryParams: query,
        asset_category:
          categoryType === "All"
            ? categoryTypes.length > 0
              ? categoryTypes.slice(1) // Category type: ["All", "Marketing", ...]. This removes the "All", as it isn't recognized from the BE
              : "All"
            : categoryType,
      })
    );

  const assetTypeParams = (() => {
    const params = new URLSearchParams();
    if (title === "Documents") {
      params.append("asset_type", "document");
    } else {
      ["video", "image"].forEach((type) => {
        params.append("asset_type", type);
      });
    }
    return params;
  })();
  const { error: categoryTypeError } = useSWR<AssetCategory>(
    `${endpoints.v2_tenants_id_pim_assets_categories(
      tenant_id
    )}?${assetTypeParams}&deduped=true`,
    {
      onSuccess: ({ asset_categories }) =>
        setCategoryTypes(["All", ...asset_categories.map(({ name }) => name)]),
    }
  );

  const isLoading = !assetsResponse && !assetsError;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: "" });
  };

  const changeFilterType = (categoryType: string) => {
    setCategoryType(categoryType);
  };

  const changeViewType = (view_type: string) => {
    setViewType(view_type === t("List") ? "list" : "grid");
  };

  const [tableData, setTableData] = useState<Assets[]>([]);
  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Asset Name"),
        accessor: "name",
        Cell: ({
          value,
          row: {
            original: { asset_type, is_generated, content_type },
          },
        }: {
          value: string;
          row: {
            original: {
              asset_type: string;
              is_generated: boolean;
              content_type: string | null;
            };
          };
        }) => {
          return (
            <FlexWrapper>
              {is_generated ? (
                <div
                  data-tip={t("Generated Document")}
                  data-for="generated-doc-tooltip"
                >
                  <MagicDocIcon />
                  <ReactTooltip effect="solid" id="generated-doc-tooltip" />
                </div>
              ) : (
                <div>
                  <GetDocumentIcon
                    asset_type={asset_type as SupportedAssetType}
                    file_type={get_content_type(
                      content_type ?? "application/pdf"
                    )}
                  />
                </div>
              )}
              <TrimmedName text={value} />
            </FlexWrapper>
          );
        },
      },
      {
        Header: t("Category"),
        accessor: "asset_category",
      },
    ],
    [t]
  );

  useEffect(() => {
    const handleProductsData = ({ data }: PIMAssetsResponse) => {
      setTableData(
        data.map(
          ({
            id,
            name,
            asset_category,
            category,
            language,
            asset_type,
            is_generated,
            content_type,
            preview_url,
          }) =>
            ({
              id,
              name: name ?? "--",
              category,
              asset_category,
              language,
              asset_type,
              is_generated,
              content_type,
              preview_url,
            } as Assets)
        )
      );
    };

    if (assetsResponse) {
      const { data: assets, pagination } = assetsResponse;
      const assetsData =
        title === "Documents"
          ? assets?.filter(({ asset_type }) => asset_type === "document")
          : assets?.filter(({ asset_type }) => asset_type !== "document");
      handleProductsData({ data: assetsData, pagination });
    }
  }, [assetsResponse, title]);

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, debouncedSearchQuery]);

  const handleSelectRows = (rows: Record<string, Array<Row<Assets>>>) => {
    if (rows) {
      setSelectedRows(rows);
    }
  };

  const default_submit = async () => {
    try {
      const { data: updatedProduct } = await updateProductStatus();

      const paramsList = selectedItems!.map(
        ({ original: { id, category, name } }: { original: Assets }) => {
          return {
            id,
            name,
            category_id: category.id,
            product_ids: [updatedProduct.id],
          };
        }
      );

      const promises = paramsList.map((params) => {
        return Axios.patch(
          endpoints.v2_storefronts_id_pim_assets_id(tenant_id, params?.id),
          params
        );
      });
      await Promise.all(promises);
      notifySuccess(t("Assets successfully added"));
      if (onComplete) {
        onComplete(
          updatedProduct?.primary_staged_product_id ?? updatedProduct?.id
        );
      }
      fetchParentData();
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("could not add an asset, Something went wrong."));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    if (!selectedItems || selectedItems.length === 0) {
      notifyError(t("Please select an asset and continue"));
    } else {
      setSubmitting(true);
      if (onAddAssets) {
        await onAddAssets({
          selected_assets: selectedItems.map(({ original }) => original),
          selected_product,
          selected_language,
        });
        setSubmitting(false);
      } else {
        default_submit();
      }
    }
  };

  const selectProduct = (selected_product: OptionType<string>) => {
    set_selected_product(selected_product);
  };

  const selectLanguage = (selected_language: OptionType<SupportedLanguage>) => {
    set_selected_language(selected_language);
  };

  useEffect(() => {
    if (selectedRows) {
      const selectedItems = Object.values(selectedRows).flat();
      setSelectedItems(selectedItems);
    }
  }, [selectedRows]);

  const numOfAssets = tableData.length ?? 0;

  const has_selected_assets = selectedItems && selectedItems.length > 0;
  return (
    <>
      <div style={{ margin: "24px 0px 16px 0px" }}>
        <H6>{t("Choose assets")}</H6>
      </div>
      <SearchWrapper>
        <SearchInput
          placeholder={t("Search by name")}
          name="asset_name"
          onChange={handleSearch}
          value={searchQuery}
        />
        {searchQuery ? (
          <ClearInput onClick={handleClearSearch}>
            <XIcon width={20} height={20} />
          </ClearInput>
        ) : (
          <SearchIcon />
        )}
      </SearchWrapper>
      <TableContainer>
        <FlexWrapper style={{ justifyContent: "space-between" }}>
          <InnerTitleText>
            {t("Showing {{numOfAssets}} assets", { numOfAssets })}
          </InnerTitleText>
          <div style={{ display: "flex" }}>
            <FilterDropdownContainer>
              <DropDown
                items={[t("List") as string, t("Grid") as string]}
                activeItem={viewType === "list" ? t("List") : t("Grid")}
                textLeft={t("View") + ":"}
                direction={"left"}
                className={"view_type"}
                clickHandler={changeViewType}
              ></DropDown>
            </FilterDropdownContainer>
            {!categoryTypeError && (
              <FilterDropdownContainer>
                <DropDown
                  items={categoryTypes}
                  activeItem={categoryType}
                  textLeft={t("Filter") + ":"}
                  direction={"left"}
                  className={"category_type"}
                  clickHandler={changeFilterType}
                ></DropDown>
              </FilterDropdownContainer>
            )}
          </div>
        </FlexWrapper>
        <ScrollArea>
          {viewType === "list" ? (
            <Table
              columns={tableColumns}
              data={tableData}
              isLoading={isLoading}
              error={assetsError}
              handleSelectRows={handleSelectRows}
              defaultSelectedRows={selectedRows}
            />
          ) : (
            <GridAssetView
              assets={
                assetsResponse?.data
                  ? assetsResponse.data.map((asset) => ({
                      ...asset,
                      can_edit: false,
                      can_change_visibility: false,
                      can_delete: false,
                      can_external_download: true,
                      can_view_details: true,
                    }))
                  : []
              }
              on_download_asset={(asset) => {
                window.open(asset.signed_url, "_blank");
              }}
              handleSelectedItems={(items) => handleSelectRows({ "1": items })}
              selectedItems={selectedItems}
              is_existing_asset_page={true}
            />
          )}
        </ScrollArea>
      </TableContainer>
      {languages && (
        <SingleSelectNoController
          options={languages}
          name="language"
          placeholder={t("Language")}
          handleChange={selectLanguage}
          {...(languages.length === 1 ? { defaultValue: languages[0] } : {})}
        />
      )}
      {products && (
        <SingleSelectNoController
          options={products}
          name="products"
          placeholder={t("Assign to product")}
          handleChange={selectProduct}
          {...(products.length === 1 ? { defaultValue: products[0] } : {})}
        />
      )}
      <PrimaryButtonFitContainer
        style={{ marginTop: "32px" }}
        type="submit"
        loading={submitting}
        onClick={handleSubmit}
        disabled={!has_selected_assets}
        datafor={has_selected_assets ? "" : "save-button-tooltip"}
        datatip={
          has_selected_assets ? "" : t("Please select an asset and continue")
        }
      >
        {t("Save")}
      </PrimaryButtonFitContainer>
      <ReactTooltip effect="solid" id="save-button-tooltip" />
    </>
  );
};
