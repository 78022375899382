import type { FC } from "react";
import React, { memo, useContext, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { useAuthContext } from "../components/Auth";
import { NotFoundPage } from "../pages/Misc/NotFoundPage";
import { UnauthorizedPage } from "../pages/Misc/Unauthorized";
import type {
  ExternalRouteType,
  IRoutePathKey,
  IRoutePaths,
  RouteType,
  User,
  CustomPageConfig,
  StorefrontMetaData,
  LayoutComponentProps,
  RouteConfiguration,
} from "../types/types";

import {
  BuyerAdminPIMRoutes,
  BuyerAdminRoutes,
  BuyerRoutes,
  BuyerUnactivatedRoutes,
  DashboardAndAnalyticsRoutes,
  PublicRoutes,
  SellerAdminRoutes,
  SellerPIMRoutes,
  SellerRoutes,
} from "../Routes";
import { LayoutPublic } from "../layout/LayoutPublic";
import { CustomStorefrontPage } from "../pages/SharedPages/CustomStorefrontPage/CustomStorefrontPage";
import {
  HomeIcon,
  LeadsIcon,
  NavQuotesIcon,
  OrderIcon,
  QuoteIcon,
  SettingsIcon,
  StackIcon,
  UsersIcon,
} from "../components/Icons/Icons";
import { getRestrictedRoutes, useStoreState } from "./util";
import { match } from "ts-pattern";
import { ErrorHandler } from "../ErrorHandler";
import { CookiePolicyModal } from "../components/CookiePolicyModal/CookiePolicyModal";
import { useCookies } from "react-cookie";
import isEmpty from "lodash/isEmpty";
import { Store } from "../Store";
import type { IStore } from "../Store";

type MapAllowedRoutesProps = {
  routes: RouteType[];
};

export const useRoutePath = (): IRoutePaths => {
  const { slug } = useStoreState();
  return {
    accountPath: `/store/${slug}/account`,
    adminPath: `/store/${slug}/admin`,
    storePath: `/store/${slug}`,
  };
};

/**
 * Utility function component for redirecting using route paths. Allows simple
 * redirect pages defined directly in route config objects (e.g. Routes.tsx).
 */
export const RedirectPage = ({
  routePathKey,
  path,
}: {
  routePathKey: IRoutePathKey;
  path: string;
}) => {
  const allRoutePaths = useRoutePath();
  const routePath = allRoutePaths[routePathKey];
  return <Redirect to={routePath + path} />;
};

/**
 *  if portfolio exists, go there. if home exists
 * go there. Otherwise to login.
 */
const getCorrectRedirect = (config: RouteConfiguration) => {
  const redirectPriority = ["portfolio", "home"];

  for (const route of redirectPriority) {
    const configItem = config.find(
      (item) => item.route === route && item.enabled
    );
    if (configItem) {
      return <RedirectPage routePathKey="storePath" path={`/${route}`} />;
    }
  }

  return <RedirectPage routePathKey="storePath" path="/login" />;
};

/**
 * Utility function component to save a path that will be redirected to, after login
 * This is useful when a path is accessed via a link (like in an email), but the user is logged out
 * It ensures that user is led to desired path, after login, and not the default path
 */
const SavePathAndRedirectPage = ({
  path,
  tenantSlug,
}: {
  path: string;
  tenantSlug: string;
}) => {
  // page reloads lots of time, it is necessary to use
  // session storage to persist data across reloads
  sessionStorage.setItem("initialPath", path);
  return <Redirect to={`/store/${tenantSlug}/login`} />;
};

const MapAllowedRoutes = memo(function MapAllowedRoutes({
  routes,
}: MapAllowedRoutesProps) {
  const tenantSlug = window.location.pathname.split("/")[2];
  const { role } = useAuthContext();
  const { storefront_metadata } = useStoreState();
  const { route_configuration } = storefront_metadata;
  const [cookies] = useCookies(["has-accepted-cookie-policy"]);
  const [showCookieModal, setShowCookieModal] = useState(
    isEmpty(cookies["has-accepted-cookie-policy"])
  );

  const getLayoutProps = (
    layout: React.FC<LayoutComponentProps>,
    layoutProps?: {
      omitUserArea?: boolean;
      omitHeader?: boolean;
      fullWidth?: boolean;
      mxWidth?: string;
    }
  ) => {
    return {
      ...layoutProps,
      mxWidth:
        layout.name === "LayoutPublic"
          ? storefront_metadata?.homepage_settings?.mx_width ?? undefined
          : undefined,
    };
  };
  const path =
    window.location.pathname +
    (window.location.search ? `${window.location.search}` : "");

  return (
    <Switch>
      {routes.map((route) => {
        const {
          path,
          component: Component,
          children,
          layout: RouteLayout,
          layoutProps,
          browserTitle,
          ...rest
        } = route;

        return (
          <Route {...rest} key={path} path={path}>
            <CookiePolicyModal
              show={showCookieModal}
              closeModal={() => setShowCookieModal(false)}
            />
            <RouteLayout
              routes={routes}
              {...getLayoutProps(RouteLayout, layoutProps)}
            >
              <Component children={children} />
            </RouteLayout>
          </Route>
        );
      })}
      {role === "guest" && (
        <Route path={`/store/${tenantSlug}/admin/pim/products/new`}>
          <SavePathAndRedirectPage path={path} tenantSlug={tenantSlug} />
        </Route>
      )}

      {/* if the role is guest and they're navigating to the app from a link (most likely an email link) that leads to orders, quotes or leads (other pages can be added as needed), this will save that intended path, and on login, redirect to that path. */}
      {role === "guest" && (
        <Route
          path={`/store/${tenantSlug}/(account|admin)/(orders|quotes|leads)/*`}
        >
          <SavePathAndRedirectPage path={path} tenantSlug={tenantSlug} />
        </Route>
      )}

      {/* if the role is guest and they hit anything started with account/admin we redirect to login for the storefront. */}
      {role === "guest" && (
        <Route path={`/store/${tenantSlug}/(account|admin)/*`}>
          <Redirect to={`/store/${tenantSlug}/login`} />
        </Route>
      )}

      {/* if they are not guest this means that they are Logged in but non of the routes above match
          they eventually hit the bottom and get to unauthorized. */}
      {role !== "guest" && (
        <Route path={`/store/${tenantSlug}/(account|admin)/*`}>
          <UnauthorizedPage tenantSlug={tenantSlug} />
        </Route>
      )}

      {/* Based on the values of route configuration we redirect to the correct page,
       keeping in mind that any combination of these public routes might exist */}
      <Route exact path={`/store/${tenantSlug}`}>
        {getCorrectRedirect(route_configuration)}
      </Route>

      {/* redirect `STORE_PATH/product` or `STORE_PATH/products` to the portfolio page instead of 404 page */}
      <Route exact path={`/store/${tenantSlug}/(product|products)`}>
        <Redirect to={`/store/${tenantSlug}/portfolio`} />
      </Route>

      {/* any other route not listed in the above routes, will redirect to the 404 page  */}
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
});

/**
 * These are the icons that can be used in the nav link for a custom page.
 */
const iconLookup = new Map([
  ["HomeIcon", HomeIcon],
  ["LeadsIcon", LeadsIcon],
  ["NavQuotesIcon", NavQuotesIcon],
  ["OrderIcon", OrderIcon],
  ["QuoteIcon", QuoteIcon],
  ["SettingsIcon", SettingsIcon],
  ["StackIcon", StackIcon],
  ["UsersIcon", UsersIcon],
]);

/**
 * Converts an array of route configs for the custom storefront pages into
 * routes -- pages where the HTML and CSS is being provided by the backend.
 */
const CustomPagesConfigsToRoutes = (
  customPages?: CustomPageConfig[]
): RouteType[] => {
  const { storeState } = useContext<IStore>(Store);
  const [cookies] = useCookies([`preferred-language-${storeState.slug}`]);
  const preferred: string | undefined =
    cookies[`preferred-language-${storeState.slug}`];
  const convertConfigToRoute = (pageConfig: CustomPageConfig): RouteType => {
    const {
      path,
      nav_link_text,
      nav_link_icon,
      layout_omit_user_area,
      layout_omit_header,
      layout_full_width,
    } = pageConfig;

    const translated_nav_link_text =
      pageConfig.page_content.find((content) => content.language === preferred)
        ?.nav_link_text || nav_link_text;

    const route: RouteType = {
      component: () => <CustomStorefrontPage pageConfig={pageConfig} />,
      exact: true,
      layout: LayoutPublic,
      path: `/store/:tenantSlug${path}`,
      // The browserTitle is set dynamically when the user visits the page, to
      // allow it to be in the correct language.
      browserTitle: "",
      layoutProps: {
        omitUserArea: layout_omit_user_area,
        omitHeader: layout_omit_header,
        fullWidth: layout_full_width,
      },
    };

    return translated_nav_link_text
      ? {
          ...route,
          nav: {
            public: {
              name: translated_nav_link_text,
              icon: nav_link_icon
                ? iconLookup.get(nav_link_icon) ?? undefined
                : undefined,
            },
          },
        }
      : route;
  };

  const customPagesRoutes = customPages?.map(convertConfigToRoute) ?? [];

  return customPagesRoutes;
};

export const AllowedRoutes = () => {
  const { edition, storefront_metadata, slug } = useStoreState();
  const { role, hasPermission, isLoadingSession, user } = useAuthContext();
  const [cookies] = useCookies([`preferred-language-${slug}`]);

  const is_buyer_activated = user?.is_buyer_activated ?? false;

  const { route_configuration } = storefront_metadata;

  // Routes for custom pages where the HTML and CSS comes from the server
  // (e.g. home pages).
  const customPagesRoutes = CustomPagesConfigsToRoutes(
    storefront_metadata.custom_pages?.filter((page) => !page.is_system)
  );

  if (role === undefined || role === null) {
    ErrorHandler.report("role is undefind or null, cant generate route tree.");
  }
  const roleRoutes = () =>
    match(role)
      .with("seller_admin", () => [
        ...DashboardAndAnalyticsRoutes,
        ...SellerAdminRoutes,
      ])
      .with("buyer_admin", () =>
        is_buyer_activated
          ? edition === "pim"
            ? BuyerAdminPIMRoutes
            : BuyerAdminRoutes
          : BuyerUnactivatedRoutes
      )
      .with("buyer_standard", () => BuyerRoutes)
      .with("distributor", () => BuyerRoutes)
      .with("distributor_admin", () =>
        is_buyer_activated
          ? edition === "pim"
            ? BuyerAdminPIMRoutes
            : BuyerAdminRoutes
          : BuyerUnactivatedRoutes
      )
      .with("seller_standard", () => [
        ...DashboardAndAnalyticsRoutes,
        ...SellerRoutes,
      ])
      .with("guest", () => [])
      .exhaustive();

  // This is temporary until all routes have permissions.
  const pimRoutes =
    role === "seller_admin" || role === "seller_standard"
      ? SellerPIMRoutes.filter((route) => {
          return hasPermission(route.permission);
        })
      : [];

  const availablePublicRoutes = PublicRoutes.reduce((acc, route) => {
    const routeConfig = route_configuration.find(
      (r) => r.route === route.internalName
    );
    const preferred = cookies[`preferred-language-${slug}`] ?? "en";
    if (
      routeConfig?.enabled &&
      routeConfig?.translations[preferred] &&
      route?.nav?.public?.name
    ) {
      acc.push({
        ...route,
        ...{
          nav: { public: { name: routeConfig?.translations[preferred] } },
        },
      });
    } else {
      acc.push(route);
    }

    return acc;
  }, [] as RouteType[]);

  const userRoutes = [
    ...customPagesRoutes,
    ...roleRoutes(),
    ...availablePublicRoutes,
  ];
  const routePaths = new Set<string>();

  const uniqueUserRoutes = userRoutes.filter(
    (route: RouteType | ExternalRouteType) => {
      const duplicateRoute = routePaths.has(route.path);
      /**
       * check if the route is enabled or disabled based on the storefront metadata
       * right now the Metadata from the backend only control digital marketing
       * routes, so `undefined` means the route is NOT controlled by the backend and
       * that's the case for all the routes except the marketing routes are
       * controlled by `digital_marketing_enabled` from the storefront metadata.
       * TODO: this should be controlled by permissions.
       */
      const allowedMetadataRoute =
        route.metaName === undefined ||
        storefront_metadata[route.metaName as keyof StorefrontMetaData];

      routePaths.add(route.path);
      return !duplicateRoute && allowedMetadataRoute;
    }
  );

  //TODO: is it more declarative here to have get the routes that are needed
  //rather than filter out the routes that are not?

  const editionRoutes = uniqueUserRoutes.filter(
    (route: RouteType | ExternalRouteType) => {
      // always ['store', 'tenantslug', ...paths].
      const paths = route.path
        .split("/")
        .filter((val) => !!val)
        .slice(2);
      const pathName = ["admin", "account"].includes(paths[0])
        ? paths[1] ?? ""
        : paths[0] ?? "";
      const restrictedRoutes = getRestrictedRoutes(edition);
      return !restrictedRoutes.includes(pathName.toLowerCase());
    }
  );

  // this is done this way for route ordering in the nav bar.
  const [dashboardAndAnalytics, restEditionRoutes] = editionRoutes.reduce<
    [RouteType[], RouteType[]]
  >(
    (acc, cur) => {
      if (cur.path.includes("/dashboard") || cur.path.includes("/analytics")) {
        acc[0].push(cur);
      } else {
        acc[1].push(cur);
      }
      return acc;
    },
    [[], []]
  );
  const finalRoutes = [
    ...dashboardAndAnalytics,
    ...pimRoutes,
    ...restEditionRoutes,
  ];

  return (
    // The following suspense boundary exists for prevents loading of any page
    // until translations have loaded.
    <React.Suspense fallback={null}>
      {/* TODO how can we use suspense for data that is fetched in context? */}
      {!isLoadingSession && <MapAllowedRoutes routes={finalRoutes} />}
    </React.Suspense>
  );
};

/**
 * A function that "wraps" a component, typically a page component, in a parent
 * component that ensures that certain things are always defined before passing
 * them as props to the page component.
 *
 * For example, the `user` object which is needed for many "behind login"
 * private page components. This way TypeScript knows the `user` object prop is
 * not null. Indeed the primary purpose of this function is so that TypeScript
 * will know things are defined and we can avoid unnecessary defensive coding.
 *
 * The `user` object should actually always be defined here, because if it
 * is not then the route for a private page component should not even exist and
 * that page component would never be rendered.  However, for TypeScript's sake
 * and just for good measure, if the `user` object is not defined here then we
 * redirect to a login page.
 */
export const providePrivatePageProps = (
  PageComponent: FC<{ user: User }>
): FC => {
  const ParentComponent = () => {
    const { user } = useAuthContext();
    const { tenantSlug } = useParams<{ tenantSlug: string }>();

    if (!user) {
      return <Redirect to={`/store/${tenantSlug}/login`} />;
    }
    return <PageComponent user={user} />;
  };

  return ParentComponent;
};
