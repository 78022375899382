import { useHistory } from "react-router-dom";
import {
  FullWidthHorizontalSeparator,
  PageTitle,
  PageWrapper,
} from "../../../../../../layout/portalPageLayout";
import { useRoutePath } from "../../../../../../util/Routing";
import { useTranslation } from "react-i18next";
import {
  ButtonWithWarningDialog,
  GoBackButtonSmall,
  InvisibleButton,
} from "../../../../../../components/Buttons/Buttons";
import { useEffect, useState } from "react";
import { UploadedAssetsList } from "./UploadedAssetsList";
import { AddAssetFiles } from "./AddAssetFiles";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";

export const AssetBulkUploadHomePage = () => {
  const { adminPath } = useRoutePath();
  const { t } = useTranslation();
  const [stage, setStage] = useState<"list" | "add_files">("list");
  const [canExit, setCanExit] = useState<boolean>(true);
  const [query, setQuery] = useQueryParams({
    uploadUUID: StringParam,
    uploadNumber: StringParam,
    count: NumberParam,
  });
  const [uploadNumber, setUploadNumber] = useState<string | undefined>();
  const history = useHistory();

  const onCompleteUpload = () => {
    setStage("list");
    setQuery({
      uploadNumber: undefined,
      uploadUUID: undefined,
      count: undefined,
    });
    setUploadNumber(undefined);
    setCanExit(true);
  };

  const goBack = () => {
    if (stage === "list") {
      history.push(`${adminPath}/pim/assets`);
    } else {
      onCompleteUpload();
    }
  };

  useEffect(() => {
    if (query.uploadNumber && !uploadNumber) {
      setUploadNumber(query.uploadNumber);
      setStage("add_files");
      setCanExit(false);
    }
  }, [query.uploadNumber, uploadNumber]);

  return (
    <PageWrapper>
      <div style={{ width: "fit-content" }}>
        {canExit ? (
          <InvisibleButton onClick={goBack}>
            <GoBackButtonSmall text={t("Back")} />
          </InvisibleButton>
        ) : (
          <ButtonWithWarningDialog
            Button={(props) => (
              <InvisibleButton {...props}>
                <GoBackButtonSmall text={"Back"} />
              </InvisibleButton>
            )}
            testid={`close_upload_${uploadNumber}`}
            confirmMessage={t(
              "Your files have not been saved yet. Leaving this page will cancel your assets upload. Are you sure you want to continue?"
            )}
            handleConfirm={goBack}
            heading={t("Cancel Upload?")}
            id={uploadNumber}
          />
        )}
      </div>
      <PageTitle>{t("Bulk Upload Assets")}</PageTitle>
      <FullWidthHorizontalSeparator />
      {stage === "list" ? (
        <UploadedAssetsList setStage={setStage} />
      ) : (
        <AddAssetFiles
          onCompleteUpload={onCompleteUpload}
          setCanExit={setCanExit}
        />
      )}
    </PageWrapper>
  );
};
