import styled from "styled-components";
import { SoftHeader2 } from "../../../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { DropDown } from "../../../../../components/DropDown/DropDown";
import { useMemo, useState } from "react";
import type { SellerTableProduct } from "../ProductsList.util";
import { ProductStatusUpdate } from "./ProductStatusUpdate";
import { StorefrontConnectionUpdate } from "./StorefrontConnectionUpdate";
import { AccessUpdate } from "./AccessUpdate";
import type { DataMutate } from "../../../../../types/types";
import type { PIMProductBasePaginagedOutput } from "../../../../../types/types.PIM";

const BulkActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const BulkActionOnProducts = ({
  rows,
  mutateProducts,
  onResetSelectedRows,
}: {
  rows: SellerTableProduct[];
  mutateProducts: DataMutate<PIMProductBasePaginagedOutput>;
  onResetSelectedRows: () => void;
}) => {
  const { t } = useTranslation();

  const [selectedAction, setSelectedAction] = useState<string>();

  const bulkActions = useMemo(
    () => [
      t("Change Status"),
      t("Activate/Deactivate on storefront"),
      t("Manage access"),
    ],
    [t]
  );

  const selectBulkAction = (action: string) => {
    setSelectedAction(action);
  };

  const resetBulkAction = () => setSelectedAction(undefined);

  const onCompleteAction = () => {
    resetBulkAction();
    mutateProducts();
    onResetSelectedRows();
  };

  return (
    <BulkActionWrapper>
      <SoftHeader2>{t("Bulk Actions:")}</SoftHeader2>
      <DropDown
        items={bulkActions}
        activeItem={selectedAction ?? ""}
        showIcon
        hideBorder={false}
        textLeft={t("Manage") + ":"}
        direction={"left"}
        clickHandler={selectBulkAction}
      />
      <ProductStatusUpdate
        show={selectedAction === bulkActions[0]}
        onCompleteUpdate={onCompleteAction}
        resetBulkAction={resetBulkAction}
        product_ids={rows.map(({ id }) => id)}
        onCancelUpdate={resetBulkAction}
      />
      <StorefrontConnectionUpdate
        show={selectedAction === bulkActions[1]}
        onComplete={onCompleteAction}
        onCancel={resetBulkAction}
        product_ids={rows.map(({ id }) => id)}
      />
      <AccessUpdate
        show={selectedAction === bulkActions[2]}
        product_ids={rows.map(({ id }) => id)}
        onComplete={onCompleteAction}
        onCancel={resetBulkAction}
      />
    </BulkActionWrapper>
  );
};
