import styled from "styled-components";
import type { ITableProps } from "./utils";
import { useRef, useState } from "react";
import { Table } from "./Table";
import type { Row } from "react-table";
import { useTranslation } from "react-i18next";
import { SoftHeader2 } from "../Typography/Typography";
import { TextButton } from "../Buttons/Buttons";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BulkActionWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
`;

const ResetBtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TableWithBulkAction = <TableData extends object>(
  props: ITableProps<TableData> & {
    bulkActionElement: <
      ElementProps extends {
        rows: TableData[];
        resetSelectedRows: () => void;
      }
    >(
      props: ElementProps
    ) => JSX.Element;
    pageIndex: number;
  }
) => {
  const { handleSelectRows, bulkActionElement, pageIndex } = props;
  const [rowsSelected, setRowsSelected] = useState<Row<TableData>[]>([]);
  const resetSelectedRowsRef = useRef<{ resetSelectedRows: () => void }>(null);

  const { t } = useTranslation();

  const onSelectRows = (rows: Record<string, Row<TableData>[]>) => {
    setRowsSelected(Object.values(rows).flat());
    if (handleSelectRows) {
      handleSelectRows(rows);
    }
  };
  return (
    <TableWrapper>
      {rowsSelected.length > 0 && !!resetSelectedRowsRef.current ? (
        <BulkActionWrapper>
          <ResetBtnWrapper>
            <SoftHeader2>
              {rowsSelected.length} {t("selected")}
            </SoftHeader2>
            {rowsSelected.length > 0 && (
              <TextButton
                onClick={resetSelectedRowsRef.current.resetSelectedRows}
              >
                <SoftHeader2>{t("Unselect All")}</SoftHeader2>
              </TextButton>
            )}
          </ResetBtnWrapper>
          {bulkActionElement({
            rows: rowsSelected.map((row) => row.original),
            resetSelectedRows: resetSelectedRowsRef.current.resetSelectedRows,
          })}
        </BulkActionWrapper>
      ) : (
        <></>
      )}
      <Table
        {...props}
        handleSelectRows={onSelectRows}
        ref={resetSelectedRowsRef}
        pageIndex={pageIndex}
      />
    </TableWrapper>
  );
};
