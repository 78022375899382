import styled from "styled-components/macro";
import type { OptionType } from "../../../../../../types/types";
import { screenSize } from "../../../../../../theme";
import { RegularText } from "../../../../../../components/Typography/Typography";
import type { Row } from "react-table";

export type UploadStages = "table" | "download" | "upload";

export type UploadState = {
  uploadStage: UploadStages;
  selectedTemplate?: OptionType<string>;
  showConfirmTemplateSelectionButton: boolean;
  collectionFileNames: Record<string, string>;
  selected_products?: Record<string, Array<Row<any>>>;
  export_id?: string;
};

export type UploadAction = {
  type:
    | "select_template"
    | "comfirm_template"
    | "set_export_id"
    | "set_selected_products"
    | "continue_from_download"
    | "discard_template"
    | "move_back"
    | "complete";
  payload: UploadState;
};

export type AsyncTableType = {
  id: string;
  externalId: string;
  name: string;
  chemical_name: string;
};

export const DownloadTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media ${screenSize.medium} {
    width: 100%;
  }
`;

export const RegularTextBlock = styled(RegularText)`
  display: block;
`;

export const TemplateButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;
